import { inject, injectable } from 'inversify';
import { HTTP_SERVICE } from '~/injectTypes/index.types';
import { HttpService } from '~/services/http.service';
import { map } from 'rxjs/operators';

export interface ReferralQuery {
  discountCode?: string;
  referralId?: string;
}

@injectable()
export class OrderService {
  constructor(@inject(HTTP_SERVICE) private readonly httpService: HttpService) {}

  findAllOrder(payload: { current: number; pageSize: number } = { current: 1, pageSize: 10 }) {
    return this.httpService
      .get('/api/v1/order/find', {
        params: payload,
      })
      .pipe(map(({ data }) => data));
  }

  delivered(id: string) {
    return this.httpService
      .post('/api/v1/order/deliver', {
        id,
      })
      .pipe(map(({ data }) => data));
  }

  findOrder(txHash?: string, orderId?: string, referralCode?: string) {
    return this.httpService
      .get('/api/v1/order/findOne', {
        params: { txHash, orderId, referralCode },
      })
      .pipe(map(({ data }) => data));
  }

  exportOrder(txHash?: string, orderId?: string, referralCode?: string) {
    return this.httpService
      .get('/api/v1/order/export/all', {
        params: { txHash, orderId, referralCode },
      })
      .pipe(map(({ data }) => data));
  }

  // for referral
  findReferral(payload: { current: number; pageSize: number } = { current: 1, pageSize: 10 }) {
    return this.httpService
      .get('/api/v1/referral/find', {
        params: payload,
      })
      .pipe(map(({ data }) => data));
  }

  createReferral(payload: { status: 'init' | 'completed'; discount: number }) {
    return this.httpService.post('/api/v1/referral/create', payload).pipe(map(({ data }) => data));
  }

  searchReferral(params: ReferralQuery) {
    return this.httpService
      .get('/api/v1/referral/search', {
        params,
      })
      .pipe(map(({ data }) => data));
  }

  exportReferral() {
    return this.httpService.get('/api/v1/referral/export').pipe(map(({ data }) => data));
  }

  updateReferralDiscount(payload: { discount: number; _id: string }) {
    return this.httpService.put('/api/v1/referral/update/discount', payload).pipe(map(({ data }) => data));
  }

  /**
   * update referral info
   * @param payload
   */
  updateReferralStatus(payload: any) {
    return this.httpService.post('/api/v1/referral/update', payload).pipe(map(({ data }) => data));
  }
}
