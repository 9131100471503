import React from 'react';
import { Table, Card, Drawer, Divider, message, Button } from 'antd';
import { Column } from './config';
import { Subscription } from 'rxjs';
import { configService } from '~/inversify.config';
import produce from 'immer';
import { CommonModal } from './commonModal';
import { sizeData } from './constants';

export interface PageDataProps {
  data: any[];
  configData: any[];
  loading: boolean;
  visible: boolean;
  configKey: string;
  modalVisible: boolean;
  pagination: {
    total: number;
    current: number;
    pageSize: number;
  };
  reRender: number;
  editorData?: {
    id: any;
    size: string;
    price: string;
    type: string;
    intro: string;
  };
}

const ConfigPage = () => {
  const sub$ = new Subscription();

  const [pageData, setPageData] = React.useState<PageDataProps>({
    data: [],
    loading: true,
    visible: false,
    configData: [],
    editorData: {
      id: '',
      size: '',
      price: '',
      type: '',
      intro: '',
    },
    reRender: 1,
    pagination: {
      total: 10,
      current: 1,
      pageSize: 10,
    },
    configKey: '',
    modalVisible: false,
  });

  React.useEffect(() => {
    const contact$ = configService.findConfigInfo().subscribe(({ data }) => {
      setPageData(
        produce((draft) => {
          draft.data = data;
          draft.loading = false;
        })
      );
    });
    sub$.add(contact$);
    return () => {
      sub$.unsubscribe();
    };
  }, [pageData.reRender]);

  const checkConfigContent = (val: any, key: string) => {
    setPageData(
      produce((draft) => {
        draft.visible = true;
        draft.configData = val;
        draft.configKey = key;
      })
    );
  };

  const handleEditor = (val: any) => {
    setPageData(
      produce((draft) => {
        draft.modalVisible = true;
        draft.editorData = val;
      })
    );
  };

  const handleOperator = (data: any, errText: string, successText: string) => {
    const { configKey } = pageData;
    const r$ = configService.createConfigInfo({ key: configKey, content: data }).subscribe(({ code }) => {
      if (code) {
        setPageData(
          produce((draft) => {
            draft.visible = false;
            draft.reRender += 1;
            draft.modalVisible = false;
            draft.editorData = undefined;
          })
        );
        message.success(successText);
      } else {
        message.error(errText);
      }
    });
    sub$.add(r$);
  };

  const handleDelete = (val: any) => {
    const { configData } = pageData;
    const filterData = configData.filter((item) => item.id !== val.id);
    handleOperator(filterData, 'delete failed', 'delete success');
  };

  const handleSave = (val: any) => {
    const { configData } = pageData;
    const filterData = configData.map((item) => {
      if (item.id === val.id && val.id && item.id) {
        return {
          ...item,
          ...val,
        };
      }
      return item;
    });
    if (!val.id) {
      filterData.push({
        ...val,
        id: new Date().getTime() + '',
      });
    }
    handleOperator(filterData, 'editor failed', 'editor success');
  };

  const handleCancel = () => {
    setPageData(
      produce((draft) => {
        draft.modalVisible = false;
        draft.editorData = undefined;
      })
    );
  };

  const initSizeData = () => {
    const config$ = configService
      .createConfigInfo({
        key: 'size_info',
        content: sizeData,
      })
      .subscribe(({ code }) => {
        if (code) {
          setPageData(
            produce((draft) => {
              draft.reRender += 1;
            })
          );
          message.success('init success');
        }
      });
    sub$.add(config$);
  };

  const handleAdd = () => {
    setPageData(
      produce((draft) => {
        draft.modalVisible = true;
      })
    );
  };
  return (
    <Card
      bordered={false}
      title='Config Info'
      extra={
        <Button type='primary' onClick={initSizeData}>
          init data
        </Button>
      }>
      <Table
        bordered
        rowKey='_id'
        loading={pageData.loading}
        dataSource={pageData.data}
        pagination={pageData.pagination}
        columns={Column(checkConfigContent)}
      />
      <Drawer
        width={1000}
        title='NFT size info'
        visible={pageData.visible}
        extra={<a onClick={handleAdd}>Add</a>}
        onClose={() =>
          setPageData(
            produce((draft) => {
              draft.visible = false;
            })
          )
        }>
        <Table
          bordered
          rowKey='id'
          dataSource={pageData.configData}
          columns={[
            { title: 'size', align: 'center', dataIndex: 'size' },
            { title: 'price', align: 'center', dataIndex: 'price' },
            { title: 'type', align: 'center', dataIndex: 'type' },
            { title: 'intro', align: 'center', dataIndex: 'intro' },
            { title: 'unit', align: 'center', dataIndex: 'unit' },
            {
              title: 'operator',
              align: 'center',
              render: (val) => (
                <>
                  <a onClick={() => handleEditor(val)}>editor</a>
                  <Divider type='vertical' />
                  <a style={{ color: 'red' }} onClick={() => handleDelete(val)}>
                    delete
                  </a>
                </>
              ),
            },
          ]}
        />
      </Drawer>
      {pageData.modalVisible && (
        <CommonModal
          handleSave={handleSave}
          handleCancel={handleCancel}
          initValue={pageData.editorData}
          modalVisible={pageData.modalVisible}
        />
      )}
    </Card>
  );
};
export default ConfigPage;
