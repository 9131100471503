import React from 'react';
import { Table, Card, message, Input, Button, Drawer, Form, Divider, Popconfirm, Modal } from 'antd';
import { Column } from './config';
import { orderService } from '~/inversify.config';
import { Subject, switchMap } from 'rxjs';
import produce from 'immer';
import { TablePaginationConfig } from 'antd/es';
import { ExportToCsv } from 'export-to-csv';
import { CommonModal } from '~/pages/config/commonModal';
import { ReferralType, PriceInfo, PageData } from '~/pages/referral/interface';

const ReferralPage = () => {
  const [form] = Form.useForm();

  const subject$ = new Subject<{ current: number; pageSize: number }>();

  const [pageData, setPageData] = React.useState<PageData>({
    data: [],
    referral: [],
    visible: false,
    loading: true,
    pagination: {
      total: 10,
      current: 1,
      pageSize: 2,
    },
    editorData: {},
    configData: [],
    modalVisible: false,
    referralId: undefined,
    referralVisible: false,
    discountCode: undefined,
    editorReferral: undefined,
  });

  const sub$ = subject$
    .pipe(
      switchMap((val) => {
        return orderService.findReferral(val);
      })
    )
    .subscribe(({ data, code }) => {
      if (code) {
        const { data: referral, pagination } = data;
        setPageData(
          produce((draft) => {
            draft.data = referral;
            draft.loading = true;
            draft.pagination = pagination;
          })
        );
      }
    });

  React.useEffect(() => {
    subject$.next({ current: 1, pageSize: 10 });
    return () => sub$.unsubscribe();
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const { current = 1, pageSize = 10 } = pagination;
    subject$.next({ current, pageSize });
  };

  const handleSearch = () => {
    if (!pageData.referralId && !pageData.discountCode) {
      message.warning('Please enter referralId or discountCode');
      return;
    }
    const sub$ = orderService.searchReferral({ discountCode: pageData.discountCode, referralId: pageData.referralId }).subscribe((val) => {
      const { data, code } = val;
      if (code !== 1) {
        message.error('Some error occur');
      }
      setPageData(
        produce((draft) => {
          draft.data = data;
        })
      );
      sub$.unsubscribe();
    });
  };

  const handleCancel = () => {
    setPageData(
      produce((draft) => {
        draft.modalVisible = false;
        draft.editorData = undefined;
      })
    );
    subject$.next({ current: 1, pageSize: 10 });
  };

  const exportData = () => {
    const hide = message.loading('export ...');
    const options = {
      filename: `export-referral-info-${Date.now()}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'referral info',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const export$ = orderService.exportReferral().subscribe(({ data }) => {
      export$.unsubscribe();
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(
        data.map((item: any) => {
          delete item._v;
          return item;
        })
      );
      setTimeout(() => {
        hide();
      }, 1000);
    });
  };

  const handleCreateReferral = async () => {
    const { editorReferral } = pageData;
    const result = await form.validateFields();
    if (editorReferral) {
      const referral = new URL(editorReferral.referralUrl).origin;
      await handleUpdate(
        { ...editorReferral, referralUrl: `${referral}?gcsp=${result.discountCode}`, discountCode: result.discountCode },
        'update success'
      );
      form.resetFields();
      return;
    }
    const operate$ = orderService.createReferral({ ...result, status: 'init' }).subscribe(({ code, message: msg }) => {
      if (code === 1) {
        form.resetFields();
        message.success('create referral success');
        subject$.next({ current: 1, pageSize: 10 });
        setPageData(
          produce((draft) => {
            draft.referralVisible = false;
          })
        );
      } else {
        message.error(msg || 'some error occur!');
      }
    });
    sub$.add(operate$);
  };

  const handleInvalid = (discountCode: string, id: string, status: 'completed' | 'init') => {
    const update$ = orderService.updateReferralStatus({ _id: id, discountCode, status }).subscribe(({ code }) => {
      if (code === 1) {
        message.success('Update success!');
        subject$.next({ current: 1, pageSize: 10 });
      }
    });
    sub$.add(update$);
  };

  const handleCheckContent = (val: string, referral: ReferralType) => {
    setPageData(
      produce((draft) => {
        draft.visible = true;
        draft.editorReferral = referral;
        draft.configData = val ? JSON.parse(val) : [];
      })
    );
  };

  const handleUpdate = (payload: ReferralType, msg: string) => {
    const update$ = orderService.updateReferralStatus(payload).subscribe(({ code }) => {
      if (code === 1) {
        message.success(msg);
        setPageData(
          produce((draft) => {
            draft.visible = false;
            draft.modalVisible = false;
            draft.referralVisible = false;
            draft.editorData = undefined;
          })
        );
        subject$.next({ current: 1, pageSize: 10 });
      }
    });
    sub$.add(update$);
  };

  const handleDelete = (val: PriceInfo) => {
    const priceConfig = pageData.configData.filter((item) => item.id !== val.id);
    if (pageData.editorReferral) {
      handleUpdate({ ...pageData.editorReferral, priceConfig: JSON.stringify(priceConfig) }, 'Delete success!');
    }
  };

  const handleSave = (val: PriceInfo) => {
    const priceConfig = pageData.configData.map((item) => {
      if (item.id === val.id) {
        return val;
      }
      return item;
    });

    if (!val.id) {
      priceConfig.push({
        ...val,
        id: new Date().getTime() + '',
      });
    }
    if (pageData.editorReferral) {
      handleUpdate({ ...pageData.editorReferral, priceConfig: JSON.stringify(priceConfig) }, 'Update success!');
    }
  };

  const handleEditor = (val: PriceInfo) => {
    setPageData(
      produce((draft) => {
        draft.editorData = val;
        draft.modalVisible = true;
      })
    );
  };

  const handleAdd = () => {
    setPageData(
      produce((draft) => {
        draft.modalVisible = true;
      })
    );
  };

  const handleEditorReferralInfo = (val: ReferralType) => {
    setPageData(
      produce((draft) => {
        draft.referralVisible = true;
        draft.editorReferral = val;
      })
    );
    form.setFieldsValue({
      discountCode: val.discountCode,
    });
  };

  const handleDeleteReferral = (val: ReferralType) => {
    handleUpdate({ ...val, isDeleted: 1 }, 'delete success');
  };

  return (
    <Card
      bordered={false}
      title='Referral Info'
      extra={
        <Button
          type='primary'
          onClick={() => {
            setPageData(
              produce((draft) => {
                draft.referralVisible = true;
              })
            );
          }}>
          create referral
        </Button>
      }>
      <div style={{ marginBottom: 60 }}>
        <h2>Search</h2>
        <Input
          value={pageData.discountCode}
          placeholder='Please enter discountCode to search'
          style={{ width: 300, marginRight: 20 }}
          onChange={(e) =>
            setPageData(
              produce((draft) => {
                draft.discountCode = e.target.value;
              })
            )
          }
        />
        <Input
          value={pageData.referralId}
          placeholder='Please enter referralId to search'
          style={{ width: 300, marginRight: 20 }}
          onChange={(e) =>
            setPageData(
              produce((draft) => {
                draft.referralId = e.target.value;
              })
            )
          }
        />
        <Button type='primary' onClick={handleSearch}>
          Search
        </Button>
        <Button style={{ marginRight: 20, marginLeft: 20 }} type='primary' onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={exportData} type='primary' style={{ marginRight: 20 }}>
          Export Referral
        </Button>
        <Button onClick={handleCancel}>Refresh</Button>
      </div>
      <Table
        bordered
        rowKey='_id'
        dataSource={pageData.data}
        pagination={pageData.pagination}
        columns={Column({ handleInvalid, handleCheckContent, handleDelete: handleDeleteReferral, handleEditorReferralInfo })}
        onChange={(pagination) => handleTableChange(pagination)}
      />
      <Drawer
        width={1000}
        title='NFT price info'
        visible={pageData.visible}
        extra={<a onClick={handleAdd}>Add</a>}
        onClose={() =>
          setPageData(
            produce((draft) => {
              draft.visible = false;
            })
          )
        }>
        <Table
          bordered
          rowKey='id'
          dataSource={pageData.configData}
          columns={[
            { title: 'size', align: 'center', dataIndex: 'size' },
            { title: 'price', align: 'center', dataIndex: 'price' },
            { title: 'type', align: 'center', dataIndex: 'type' },
            { title: 'intro', align: 'center', dataIndex: 'intro' },
            { title: 'unit', align: 'center', dataIndex: 'unit' },
            {
              title: 'operator',
              align: 'center',
              render: (val) => (
                <>
                  <a onClick={() => handleEditor(val)}>editor</a>
                  <Divider type='vertical' />
                  <Popconfirm title='Do you want to delete it?' onConfirm={() => handleDelete(val)}>
                    <a style={{ color: 'red' }}>delete</a>
                  </Popconfirm>
                </>
              ),
            },
          ]}
        />
      </Drawer>
      {pageData.modalVisible && (
        <CommonModal
          handleSave={handleSave}
          handleCancel={handleCancel}
          initValue={pageData.editorData}
          modalVisible={pageData.modalVisible}
        />
      )}
      <Modal
        title='Create / editor referral'
        onCancel={() => {
          setPageData(
            produce((draft) => {
              draft.referralVisible = false;
            })
          );
          form.resetFields();
        }}
        visible={pageData.referralVisible}
        onOk={() => handleCreateReferral()}>
        <Form form={form} autoComplete='off'>
          <Form.Item label='discountCode' name='discountCode' rules={[{ required: true, message: 'Please input discount code!' }]}>
            <Input placeholder='Please input discount code!' />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
export default ReferralPage;
