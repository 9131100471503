import React from 'react';

import styles from './index.module.scss';
import { message } from 'antd';
import { userService } from '~/inversify.config';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import logo from './images/logo.png';
import produce from 'immer';

const LoginPage = () => {
  const navigate = useNavigate();
  const [pageData, setPageData] = React.useState({
    username: '',
    password: '',
  });
  const subject$ = new Subject<{ username: string; password: string }>();
  subject$
    .pipe(
      debounceTime(300),
      switchMap((val) => {
        return userService.login(val);
      })
    )
    .subscribe((val) => {
      if (val.code) {
        navigate('/');
      } else {
        message.error(val.message);
      }
    });

  React.useEffect(() => {
    return () => {
      subject$.unsubscribe();
    };
  }, []);

  const login = async () => {
    subject$.next(pageData);
  };

  return (
    <div className={styles.login}>
      <div className={styles.formWrap}>
        <h1 className={styles.loginTitle}>
          <img src={logo} alt='' />
        </h1>
        <div className={styles.form}>
          <div className={styles.formItem}>
            <div className={styles.formLabel}>
              <svg className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='128' height='128'>
                <path
                  d='M512 490.666667A181.333333 181.333333 0 1 1 512 128a181.333333 181.333333 0 0 1 0 362.666667zM128 853.333333v-21.333333a298.666667 298.666667 0 0 1 298.666667-298.666667h170.666666a298.666667 298.666667 0 0 1 298.666667 298.666667v21.333333a42.666667 42.666667 0 0 1-42.666667 42.666667H170.666667a42.666667 42.666667 0 0 1-42.666667-42.666667z'
                  fill='#ffffff'
                />
              </svg>
              User
            </div>
            <div className={styles.input}>
              <input
                type='text'
                value={pageData.username}
                onChange={(e) => {
                  setPageData(
                    produce((draft) => {
                      draft.username = e.target.value;
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.formItem}>
            <div className={styles.formLabel}>
              <svg className='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='128' height='128'>
                <path
                  d='M801.649 418.816H366.694V263.951c0-51.271 64.932-108.21 144.983-108.21 80.062 0 144.983 56.944 144.983 108.21v46.434h0.63c3.994 26.265 27.525 46.438 55.895 46.438 28.575 0 52.112-20.173 55.89-46.438h0.63v-46.439c0-119.562-115.354-216.637-257.818-216.637-142.249 0-257.607 97.075-257.607 216.637v154.87H221.92c-53.37 0-96.655 41.6-96.655 93.082V883.82c0 51.271 43.284 92.871 96.655 92.871h580.153c53.586 0 96.66-41.6 96.66-92.871V511.898c-0.22-51.482-43.924-93.082-97.085-93.082zM559.8 761.938v42.24c0 26.69-21.647 48.118-48.537 48.118a47.974 47.974 0 0 1-48.118-48.118v-42.24c-38.031-17.229-64.717-54.21-64.717-96.65 0-59.26 50.642-107.162 112.835-107.162s112.624 47.898 112.624 107.162c0.42 42.444-26.055 79.421-64.087 96.65z'
                  fill='#ffffff'
                />
              </svg>
              Password
            </div>
            <div className={styles.input}>
              <input
                type='password'
                value={pageData.password}
                onChange={(e) => {
                  setPageData(
                    produce((draft) => {
                      draft.password = e.target.value;
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.submitBtn} onClick={login}>
            login
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
