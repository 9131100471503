import { inject, injectable } from 'inversify';
import { HTTP_SERVICE } from '~/injectTypes/index.types';
import { HttpService } from '~/services/http.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
@injectable()
export class UserService {
  constructor(@inject(HTTP_SERVICE) private readonly httpService: HttpService) {}

  private emojis: Subject<{ [key: string]: any }> = new Subject();

  get fetchEmojis(): Observable<Record<string, any>> {
    return this.httpService.get<Record<string, any>>('https://api.github.com/emojis').pipe(map(({ data }) => data));
  }

  setEmojis(val: { [key: string]: any }) {
    this.emojis.next(val);
  }

  get getEmojis() {
    return this.emojis.asObservable();
  }

  /**
   * 用户登录
   * @param payload
   * @param payload.username
   * @param payload.password
   */
  login(payload: { username: string; password: string }): Observable<{ code: number; message: string }> {
    return this.httpService.post<{ code: number; message: string }>('/api/v1/user/login', payload).pipe(map(({ data }) => data));
  }

  // 获取当前用户信息
  currentUser() {
    return this.httpService.get('/api/v1/user/currentUser');
  }
}
