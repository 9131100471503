import { inject, injectable } from 'inversify';
import { HTTP_SERVICE } from '~/injectTypes/index.types';
import { HttpService } from '~/services/http.service';
import { map } from 'rxjs/operators';
@injectable()
export class ConfigService {
  constructor(@inject(HTTP_SERVICE) private readonly httpService: HttpService) {}

  findConfigInfo() {
    return this.httpService.get('/api/v1/common/config/findAll').pipe(map(({ data }) => data));
  }

  createConfigInfo(data: any) {
    return this.httpService.post('/api/v1/common/config/create', data).pipe(map(({ data }) => data));
  }
}
