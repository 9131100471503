import React from 'react';
import dayjs from 'dayjs';
import { Tag, Popconfirm, Button, Divider } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { ReferralType } from '~/pages/referral/interface';

export interface ReferralOperateType {
  handleDelete: (val: ReferralType) => void;
  handleEditorReferralInfo: (val: ReferralType) => void;
  handleCheckContent: (val: string, referral: any) => void;
  handleInvalid: (discountCode: string, id: string, status: 'completed' | 'init') => void;
}

export const Column = ({ handleInvalid, handleCheckContent, handleDelete, handleEditorReferralInfo }: ReferralOperateType) => {
  const column: ColumnType<any>[] = [
    {
      title: 'referralId',
      dataIndex: 'referralId',
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'discountCode',
      dataIndex: 'discountCode',
      align: 'center',
    },
    {
      title: 'referralUrl',
      dataIndex: 'referralUrl',
      align: 'center',
      render: (val) => val ?? '--',
    },
    {
      title: 'status',
      dataIndex: 'status',
      align: 'center',
      width: 140,
      render: (val) => {
        return <Tag color={val === 'init' ? 'blue' : 'green'}>{val}</Tag>;
      },
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      render: (val) => {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: 'updatedAt',
      dataIndex: 'updatedAt',
      align: 'center',
      render: (val) => {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: 'priceInfo',
      align: 'center',
      render: (val) => (
        <Button type='primary' size='middle' onClick={() => handleCheckContent(val.priceConfig, val)}>
          check
        </Button>
      ),
    },
    {
      width: '200px',
      title: 'operate',
      align: 'center',
      render: (val) => {
        return (
          <>
            <Popconfirm
              title='Does it be confirmed to be set to be invalid'
              onConfirm={() => handleInvalid(val.discountCode, val._id, val.status === 'init' ? 'completed' : 'init')}>
              <a style={{ color: 'red' }}>{val.status === 'init' ? 'invalid' : 'valid'}</a>
            </Popconfirm>
            <Divider type='vertical'></Divider>
            <Popconfirm title='Confirm to delete?' onConfirm={() => handleDelete(val)}>
              <a style={{ color: 'red' }}>delete</a>
            </Popconfirm>
            <Divider type='vertical'></Divider>
            <a onClick={() => handleEditorReferralInfo(val)}>editor</a>
          </>
        );
      },
    },
  ];
  return column;
};
