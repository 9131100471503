import { inject, injectable } from 'inversify';
import { HTTP_SERVICE } from '~/injectTypes/index.types';
import { HttpService } from '~/services/http.service';
import { map } from 'rxjs/operators';

@injectable()
export class ContactService {
  constructor(@inject(HTTP_SERVICE) private readonly httpService: HttpService) {}

  findContactInfo(payload: { current: number; pageSize: number }) {
    return this.httpService
      .get('/api/v1/common/contact', {
        params: payload,
      })
      .pipe(map(({ data }) => data));
  }
}
