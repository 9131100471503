/**
 * this file is used to manage the DI service with inversify container
 */
import { Container } from 'inversify';
import { USER_SERVICE, HTTP_SERVICE, ORDER_SERVICE, CONTACT_SERVICE, CONFIG_SERVICE } from './injectTypes/index.types';
import { UserService } from './services/user.service';
import { HttpService } from './services/http.service';
import { OrderService } from './services/order.service';
import { ContactService } from './services/contact.service';
import { ConfigService } from './services/config.service';

const container = new Container({
  defaultScope: 'Singleton',
});

container.bind<HttpService>(HTTP_SERVICE).to(HttpService);
container.bind<UserService>(USER_SERVICE).to(UserService);
container.bind<OrderService>(ORDER_SERVICE).to(OrderService);
container.bind<ContactService>(CONTACT_SERVICE).to(ContactService);
container.bind<ConfigService>(CONFIG_SERVICE).to(ConfigService);

export const userService = container.get<UserService>(USER_SERVICE);
export const orderService = container.get<OrderService>(ORDER_SERVICE);
export const contactService = container.get<ContactService>(CONTACT_SERVICE);
export const configService = container.get<ConfigService>(CONFIG_SERVICE);
