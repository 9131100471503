import React from 'react';
import { Route } from '../routes';
import { cloneDeep } from 'lodash-es';
import { SettingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

/**
 * 扁平数组数据
 * @param routes Route[] 路由
 * @param baseUrl string 基础url
 */
export const flatRouter = (routes: Route[], baseUrl = '') => {
  const copyRoutes = cloneDeep(routes);
  const hasLayout: Route[] = [];
  const noLayout: Route[] = [];
  const flat = (copyRoutes: Route[], baseUrl = '') => {
    for (const route of copyRoutes) {
      if (route.layout === false) {
        noLayout.push({
          ...route,
          icon: route.icon ?? SettingOutlined,
          path: `${baseUrl}${route.path}`,
        });
      } else {
        hasLayout.push({
          ...route,
          icon: route.icon ?? SettingOutlined,
          path: `${baseUrl}${route.path}`,
        });
      }
      if (route.children && route.children.length > 0) {
        flat(route.children, route.path);
      }
    }
  };
  flat(copyRoutes, baseUrl);
  return { noLayout, hasLayout };
};

export const shortText = (txt: string, length = 10) => {
  if (txt && txt.length > length) {
    return <Tooltip title={txt}>{txt.slice(0, length)}...</Tooltip>;
  }
  return txt ?? '--';
};
