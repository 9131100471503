/**
 * 路由配置
 */
import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import LoginPage from './pages/login';
import OrderPage from './pages/order';
import ContactPage from './pages/contract';
import NotFound from './pages/404';
import ConfigPage from './pages/config';
import ReferralPage from './pages/referral';
import { flatRouter } from './utils/utils';

export interface Route {
  path: string;
  name: string;
  layout?: boolean;
  hideMenu?: boolean;
  icon?: React.ReactNode;
  component: React.FC<any>;
  children?: Route[];
}

export const Routes: Route[] = [
  {
    path: '/',
    name: 'Order Info',
    icon: <HomeOutlined />,
    component: OrderPage,
  },
  {
    path: '/contract',
    name: 'Contact Info',
    icon: <HomeOutlined />,
    component: ContactPage,
  },
  {
    path: '/referral',
    name: 'Referral Info',
    icon: <HomeOutlined />,
    component: ReferralPage,
  },
  {
    path: '/config',
    name: 'Config Info',
    icon: <HomeOutlined />,
    component: ConfigPage,
  },
  {
    path: '/login',
    name: '登录',
    hideMenu: true,
    layout: false,
    icon: <HomeOutlined />,
    component: LoginPage,
  },
  {
    path: '*',
    name: '404',
    hideMenu: true,
    component: NotFound,
  },
];

export const flatRoutes = flatRouter(Routes);
