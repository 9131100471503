import React from 'react';
import { Table, Card, Drawer, message, Input, Button } from 'antd';
import { Column } from './config';
import { orderService } from '~/inversify.config';
import { Subject, switchMap } from 'rxjs';
import produce from 'immer';
import { TablePaginationConfig } from 'antd/es';
import { shortText } from '~/utils/utils';
import { ExportToCsv } from 'export-to-csv';

export interface PageData {
  nft: any[];
  data: any[];
  txHash?: string;
  orderId?: string;
  visible: boolean;
  loading: boolean;
  referralCode?: string;
  pagination: { total: number; current: number; pageSize: number };
}

const OrderPage = () => {
  const subject$ = new Subject<{ current: number; pageSize: number; txHash?: string; orderId?: number | string; referralCode?: string }>();

  const [pageData, setPageData] = React.useState<PageData>({
    nft: [],
    data: [],
    visible: false,
    loading: true,
    pagination: {
      total: 10,
      current: 1,
      pageSize: 10,
    },
    txHash: undefined,
    orderId: undefined,
    referralCode: undefined,
  });

  subject$
    .pipe(
      switchMap((val) => {
        return orderService.findAllOrder(val);
      })
    )
    .subscribe(({ data, code }) => {
      if (code) {
        const { data: order, pagination } = data;
        setPageData(
          produce((draft) => {
            draft.data = order;
            draft.loading = true;
            draft.pagination = pagination;
          })
        );
      }
    });

  React.useEffect(() => {
    subject$.next({ current: 1, pageSize: 10 });
    return () => subject$.unsubscribe();
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const { current = 1, pageSize = 10 } = pagination;
    subject$.next({ current, pageSize, txHash: pageData.txHash, orderId: pageData.orderId, referralCode: pageData.referralCode });
  };

  const handleCheckNFT = (val: any[]) => {
    setPageData(
      produce((draft) => {
        draft.nft = val;
        draft.visible = true;
      })
    );
  };

  const handleDeliverOrder = (id: string) => {
    const {
      pagination: { current, pageSize },
    } = pageData;
    const sub$ = orderService.delivered(id).subscribe(() => {
      message.success('Modify deliver status success!');
      if (pageData.txHash) {
        handleSearch();
      } else {
        subject$.next({ current, pageSize });
        sub$.unsubscribe();
      }
    });
  };

  const handleSearch = () => {
    if (!pageData.txHash && !pageData.orderId && !pageData.referralCode) {
      message.warning('Please enter txHash or orderId or referralCode');
      return;
    }
    subject$.next({
      current: 1,
      pageSize: 10,
      txHash: pageData.txHash,
      orderId: pageData.orderId,
      referralCode: pageData.referralCode,
    });
  };

  const handleCancel = () => {
    setPageData(
      produce((draft) => {
        draft.txHash = undefined;
        draft.orderId = undefined;
        draft.referralCode = undefined;
      })
    );
    subject$.next({ current: 1, pageSize: 10 });
  };

  const exportData = () => {
    const hide = message.loading('export ...');
    const options = {
      filename: `export-order-${Date.now()}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'order info',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const { txHash, orderId, referralCode } = pageData;
    const export$ = orderService.exportOrder(txHash, orderId, referralCode).subscribe(({ data }) => {
      export$.unsubscribe();
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(
        data.map((item: any) => {
          return {
            ...item,
            nft: JSON.stringify(item.nft),
          };
        })
      );
      setTimeout(() => {
        hide();
      }, 1000);
    });
  };

  return (
    <Card bordered={false} title='Order Info'>
      <div style={{ marginBottom: 60 }}>
        <h2>Search</h2>
        <Input
          value={pageData.txHash}
          placeholder='Please enter txHash to search'
          style={{ width: 300, marginRight: 20 }}
          onChange={(e) =>
            setPageData(
              produce((draft) => {
                draft.txHash = e.target.value;
              })
            )
          }
        />
        <Input
          value={pageData.orderId}
          placeholder='Please enter orderId to search'
          style={{ width: 300, marginRight: 20 }}
          onChange={(e) =>
            setPageData(
              produce((draft) => {
                draft.orderId = e.target.value;
              })
            )
          }
        />
        <Input
          value={pageData.referralCode}
          placeholder='Please enter referralCode to search'
          style={{ width: 300, marginRight: 20 }}
          onChange={(e) =>
            setPageData(
              produce((draft) => {
                draft.referralCode = e.target.value;
              })
            )
          }
        />
        <Button type='primary' onClick={handleSearch}>
          Search
        </Button>
        <Button style={{ marginRight: 20, marginLeft: 20 }} type='primary' onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={exportData} type='primary' style={{ marginRight: 20 }}>
          Export Order
        </Button>
        <Button onClick={handleCancel}>Refresh</Button>
      </div>
      <Table
        bordered
        rowKey='_id'
        scroll={{ x: 2700 }}
        dataSource={pageData.data}
        pagination={pageData.pagination}
        columns={Column(handleCheckNFT, handleDeliverOrder)}
        onChange={(pagination) => handleTableChange(pagination)}
      />
      <Drawer
        width={800}
        title='NFT info'
        visible={pageData.visible}
        onClose={() =>
          setPageData(
            produce((draft) => {
              draft.visible = false;
            })
          )
        }>
        <Table
          bordered
          rowKey='_id'
          dataSource={pageData.nft}
          columns={[
            {
              title: 'originUrl',
              dataIndex: 'originUrl',
              align: 'center',
              render: (val) => {
                return val ? (
                  <a href={val} target='__blank'>
                    <img src={val} style={{ width: 80 }} alt='' />
                  </a>
                ) : (
                  '--'
                );
              },
            },
            { title: 'layout', dataIndex: 'layout', align: 'center', render: (val) => shortText(val) },
            { title: 'size', dataIndex: 'size', align: 'center', render: (val) => shortText(val) },
            { title: 'frame', dataIndex: 'frame', align: 'center', render: (val) => shortText(val) },
            { title: 'ipfs', dataIndex: 'ipfs', align: 'center', render: (val) => shortText(val) },
            { title: 'nftAssetContractAddress', dataIndex: 'nftAssetContractAddress', align: 'center', render: (val) => shortText(val) },
          ]}></Table>
      </Drawer>
    </Card>
  );
};
export default OrderPage;
