export const sizeData = [
  {
    id: '1',
    size: 'XS (Square) ',
    price: '0.14',
    type: 'Square',
    unit: 'ETH',
    intro: 'XS (Square) : 0.14ETH 161mmx161mm(6.34”x6.34”)',
  },
  {
    id: '2',
    unit: 'ETH',
    size: 'XS (Rectangle) ',
    price: '0.14',
    type: 'Rectangle',
    intro: 'XS (Rectangle) : 0.14ETH 161mmx227mm(6.34”x8.94”)',
  },
  {
    id: '3',
    unit: 'ETH',
    size: 'XS (Square) ',
    price: '0.5',
    type: 'Square',
    intro: 'S (Square) : 0.5ETH 300mmx300mm(11.81”x11.81”)',
  },
  {
    id: '4',
    unit: 'ETH',
    size: 'XS (Rectangle) ',
    price: '0.5',
    type: 'Rectangle',
    intro: 'S (Rectangle) : 0.5ETH 300mmx400mm(11.81”x15.75”)',
  },
  {
    id: '5',
    unit: 'ETH',
    size: 'XS (Square) ',
    price: '0.8',
    type: 'Square',
    intro: 'M (Square) : 0.8ETH 500mmx500mm(19.69”x19.69”)',
  },
  {
    id: '6',
    unit: 'ETH',
    size: 'XS (Rectangle) ',
    price: '0.8',
    type: 'Rectangle',
    intro: 'M (Rectangle) : 0.8ETH 500mmx700mm(19.69”x27,56”)',
  },
];
