import React from 'react';
import { Modal, Form, Input } from 'antd';

export interface CommonModalProps {
  initValue: any;
  modalVisible: boolean;
  handleCancel: () => void;
  handleSave: (val: any) => void;
}

export const CommonModal = (props: CommonModalProps) => {
  const [form] = Form.useForm();
  const { modalVisible, handleSave, initValue, handleCancel } = props;

  React.useEffect(() => {
    if (initValue) {
      form.setFieldsValue(initValue);
    }
  }, [modalVisible]);

  const handleOk = async () => {
    const values = await form.validateFields();
    handleSave(values);
    form.resetFields();
  };

  return (
    <Modal
      title='editor'
      visible={modalVisible}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        form.resetFields();
      }}>
      <Form form={form} autoComplete='off'>
        <Form.Item style={{ display: 'none' }} label='size' name='id' rules={[{ required: false, message: 'Please enter size' }]}>
          <Input placeholder='Please enter size' />
        </Form.Item>
        <Form.Item label='size' name='size' rules={[{ required: true, message: 'Please enter size' }]}>
          <Input placeholder='Please enter size' />
        </Form.Item>

        <Form.Item label='price' name='price' rules={[{ required: true, message: 'Please enter price' }]}>
          <Input placeholder='Please enter price' />
        </Form.Item>
        <Form.Item label='type' name='type' rules={[{ required: true, message: 'Please enter type' }]}>
          <Input placeholder='Please enter type' />
        </Form.Item>

        <Form.Item label='intro' name='intro' rules={[{ required: true, message: 'Please enter intro!' }]}>
          <Input.TextArea placeholder='Please enter intro!' />
        </Form.Item>
        <Form.Item label='unit' name='unit' rules={[{ required: true, message: 'Please enter unit!' }]}>
          <Input.TextArea placeholder='Please enter unit!' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
