import React from 'react';
import { Table, Card } from 'antd';
import { Column } from './config';
import { Subject, switchMap } from 'rxjs';
import { contactService } from '~/inversify.config';
import produce from 'immer';
import { TablePaginationConfig } from 'antd/es';

export interface PageDataProps {
  loading: boolean;
  data: any[];
  pagination: {
    total: number;
    current: number;
    pageSize: number;
  };
}

const ContactPage = () => {
  const subject$ = new Subject<{ current: number; pageSize: number }>();
  const [pageData, setPageData] = React.useState<PageDataProps>({
    data: [],
    loading: true,
    pagination: {
      total: 10,
      current: 1,
      pageSize: 10,
    },
  });
  subject$.pipe(switchMap((val) => contactService.findContactInfo(val))).subscribe(({ data, code }) => {
    if (code) {
      const { data: order, pagination } = data;
      setPageData(
        produce((draft) => {
          draft.data = order;
          draft.loading = false;
          draft.pagination = pagination;
        })
      );
    }
  });

  React.useEffect(() => {
    subject$.next({ current: 1, pageSize: 10 });
    return () => {
      subject$.unsubscribe();
    };
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const { current = 1, pageSize = 10 } = pagination;
    console.log(current, pageSize, '90');
    subject$.next({ current, pageSize });
  };

  return (
    <Card bordered={false} title='Contact Info'>
      <Table
        bordered
        rowKey='_id'
        columns={Column()}
        loading={pageData.loading}
        dataSource={pageData.data}
        pagination={pageData.pagination}
        onChange={(pagination) => handleTableChange(pagination)}
      />
    </Card>
  );
};
export default ContactPage;
