import React from 'react';
import dayjs from 'dayjs';
import { Tag, Popconfirm } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import { fromWei } from 'web3-utils';
import { shortText } from '~/utils/utils';

export const Column = (handleCheckNFT: (val: any) => void, handleDelivered: (id: string) => void) => {
  const column: ColumnType<any>[] = [
    {
      title: 'orderId',
      dataIndex: 'orderId',
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'referralCode',
      align: 'center',
      dataIndex: 'discountCode',
      render: (val) => val || '--',
    },
    {
      title: 'txHash',
      dataIndex: 'txHash',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'txStatus',
      dataIndex: 'txStatus',
      align: 'center',
      render: (val) => {
        return <Tag color={val === 'init' ? 'blue' : 'green'}>{val}</Tag>;
      },
    },
    {
      title: 'deliverStatus',
      dataIndex: 'status',
      align: 'center',
      width: 140,
      render: (val) => {
        return <Tag color={val === 'init' ? 'blue' : 'green'}>{val}</Tag>;
      },
    },
    {
      title: 'txAmount',
      dataIndex: 'txAmount',
      align: 'center',
      render: (val) => fromWei(val, 'ether'),
    },
    {
      title: 'userAddress',
      width: 140,
      dataIndex: 'userAddress',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'receiptAddress',
      width: 140,
      dataIndex: 'receiptAddress',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'nft',
      dataIndex: 'nft',
      align: 'center',
      render: (val) => <a onClick={() => handleCheckNFT(val)}>Check</a>,
    },
    {
      title: 'email',
      dataIndex: 'email',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'mobile',
      dataIndex: 'mobile',
      align: 'center',
    },
    {
      title: 'firstName',
      dataIndex: 'firstName',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'lastName',
      dataIndex: 'lastName',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'country',
      dataIndex: 'country',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'city',
      dataIndex: 'city',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'address',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: 'apartment',
      dataIndex: 'apartment',
      align: 'center',
      render: (val) => shortText(val),
    },
    {
      title: 'remark',
      dataIndex: 'remark',
      align: 'center',
      render: shortText,
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      render: (val) => {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: 'updatedAt',
      dataIndex: 'updatedAt',
      align: 'center',
      render: (val) => {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      width: 120,
      fixed: 'right',
      title: 'operate',
      align: 'center',
      render: (val) =>
        val.status === 'init' ? (
          <Popconfirm title='Confirm deliver NFT order?' onConfirm={() => handleDelivered(val._id)}>
            <a>Confirm deliver</a>
          </Popconfirm>
        ) : (
          '--'
        ),
    },
  ];
  return column;
};
