import dayjs from 'dayjs';
import { ColumnType } from 'antd/es/table/interface';

export const Column = () => {
  const column: ColumnType<any>[] = [
    {
      title: 'name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'email',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: 'phone',
      dataIndex: 'mobile',
      align: 'center',
    },
    {
      title: 'content',
      dataIndex: 'content',
      align: 'center',
    },
    {
      title: 'createdAt',
      dataIndex: 'createdAt',
      align: 'center',
      render: (val) => {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: 'updatedAt',
      dataIndex: 'updatedAt',
      align: 'center',
      render: (val) => {
        return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ];
  return column;
};
